export default class CancelModel {
  constructor(){
    this.id = null;
    this.cancel_amount = 0;
  }
  setId(id){
    this.id = id;
  }
  setData(obj){
    const { cancel_amount } = obj;
    this.cancel_amount = cancel_amount;
  }
  getData(){
    let obj = {
      id : this.id,
      cancel_amount : Number(this.cancel_amount),
    }
    if(this.cancel_amount){
      obj.cancel_amount = Number(this.cancel_amount.replace(',',''));
    }
    return obj;
  }
}