import router from '@/router';
import { setDataList } from "@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils";
import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import { isValidPhoneNumber } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils';
import { convertDateFormat, getValueOfField } from "@lemontree-ai/lemontree-admin-common-front/utils/dateUtils";
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';
import joinModel from '@/views/service/membership/payments/model/JoinModel';

export default class CsUserByUserDataListViewModel {
  constructor() {
    this.tabData = {
      dataList: [
        { id: 'PHONE', text:'전화번호로 검색' },
        { id: 'CARD_NUMBER', text:'카드번호로 검색' },
        { id: 'UID', text:'퍼핀 UID로 검색' },
        { id: 'ID', text:'퍼핀 회원 ID로 검색' },
        { id: 'PAY_ID', text:'페이 ID로 검색' },
        { id: 'VIRTUAL_ACCOUNT_NUMBER', text:'가상계좌번호로 검색' },
      ],
      option:{
        isCount: false
      },
      value:'PHONE'
    }
    this.valueText = '';
    this.apiPathData = '';
    this.searchData = {
      PHONE:{
        searchDataList :[
          {
            title:'전화번호',
            type: 'Input',
            grid: 100,
            isFull:true,
            value:'',
            isBlockUnValueType: true,
            valueType:'tel'
          },
        ],
        searchParams : {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData : {
          totalCount:0,
          totalPage:0
        },
        dataList:[],
        responseDate:'',
      },
      CARD_NUMBER:{
        searchDataList :[
          {
            title:'카드번호',
            type: 'Input',
            grid: 100,
            isFull:true,
            value:'',
            isBlockUnValueType: true,
            valueType:'tel'
          },
        ],
        searchParams : {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData : {
          totalCount:0,
          totalPage:0
        },
        dataList:[],
        responseDate:'',
      },
      UID:{
        searchDataList :[
          {
            title:'퍼핀 UID',
            type: 'Input',
            grid: 100,
            isFull:true,
            value:'',
          },
        ],
        searchParams : {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData : {
          totalCount:0,
          totalPage:0
        },
        dataList:[],
        responseDate:'',
      },
      ID:{
        searchDataList :[
          {
            title:'퍼핀 회원 ID',
            type: 'Input',
            grid: 100,
            isFull:true,
            value:'',
          },
        ],
        searchParams : {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData : {
          totalCount:0,
          totalPage:0
        },
        dataList:[],
        responseDate:'',
      },
      PAY_ID:{
        searchDataList :[
          {
            title:'페이 ID',
            type: 'Input',
            grid: 100,
            isFull:true,
            value:'',
          },
        ],
        searchParams : {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData : {
          totalCount:0,
          totalPage:0
        },
        dataList:[],
        responseDate:'',
      },
      VIRTUAL_ACCOUNT_NUMBER:{
        searchDataList :[
          {
            title:'가상계좌번호',
            type: 'Input',
            grid: 100,
            isFull:true,
            value:'',
          },
        ],
        searchParams : {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData : {
          totalCount:0,
          totalPage:0
        },
        dataList:[],
        responseDate:'',
      }
    }
    this.boardData = {
      title:'회원 검색결과',
      drawDataList: [
        {
          title:'페이 ID',
          width:'100px',
          value:'payUserId',
          isAlignLeft: true,
        },
        {
          title:'퍼핀 회원<br>ID',
          width:'100px',
          value:'firfinUserId',
          isAlignLeft: true,
        },
        {
          title:'퍼핀 UID',
          width:'196px',
          value:'firfinUserUid',
          isAlignLeft: true,
        },
        {
          title:'가족 ID',
          width:'100px',
          value:'familyGroupId',
          isAlignLeft: true,
        },
        {
          title:'가족 상태',
          width:'96px',
          value:'familyStatus',
          filter:{
            name:'convertIdToText',
            value:'cs_family_status'
          },
          badge: {
            badgeColorFilter:{
              name:'convertIdToColor',
              dataListName:'cs_family_status'
            },
            badgeFill: false
          },
        },
        {
          title:'역할',
          width:'72px', // 60px
          value:'familyRole',
          class: {
            classColorFilter:{
              name:'convertIdToColor',
              dataListName:'cs_user_role_korean',
              prev:'fw_bold tc'
            }
          },
          valueCustom:[
            { 
              type: 'value',
              value: 'familyRole',
              filter:{
                name:'convertIdToText',
                value:'user_role'
              },
            },
            {
              type: 'value',
              value:'familyPermission',
              filter:{
                name:'convertIdToText',
                value:'user_family_leader'
              },
            },
          ],
          isAlignLeft: true,
        },
        {
          title:'회원이름',
          width:'62px',
          value:'name',
          isAlignLeft: true,
          isEllip: true
        },
        {
          title:'생년월일',
          width:'90px',
          value:'dateOfBirth',
          filter: { name:'convertDateFormat', value:'YYYY-MM-DD' },
          isAlignLeft: true,
          emptyValueText:'-'
        },
        {
          title:'휴대전화',
          width:'118px',
          value:'phone',
          filter: { name:'convertPhoneNember' },
          isAlignLeft: true,
        },
        {
          title:'등록 일시',
          width:'152px',
          value:'firfinRegisteredAt',
          filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
          isMobileTitle:true,
          isAlignLeft: true,
          // class:{
          //   name: 'fs_small'
          // },
          // valueCustom:[
          //   { 
          //     type: 'value',
          //     value: 'firfinRegisteredAt',
          //     filter: { name:'convertDateFormat', value:'YYYY-MM-DD' },
          //   },
          //   { 
          //     type: 'text',
          //     value: '<br>',
          //   },
          //   { 
          //     type: 'value',
          //     value: 'firfinRegisteredAt',
          //     filter: { name:'convertDateFormat', value:'HH:mm:ss' },
          //   },
          // ],
        },
        {
          title:'pay 계정',
          width:'72px',
          value:'payUserStatus',
          filter:{
            name:'convertIdToText',
            value:'user_status_name'
          },
          badge: {
            badgeColorFilter:{
              name:'convertIdToColor',
              dataListName:'cs_user_status_name'
            },
            badgeFill: false
          },
        },
        {
          title:'firfin 계정',
          width:'72px',
          value:'firfinUserStatus',
          filter:{
            name:'convertIdToText',
            value:'user_status_name'
          },
          badge: {
            badgeColorFilter:{
              name:'convertIdToColor',
              dataListName:'cs_user_status_name'
            },
            badgeFill: false
          },
        },
        // {
        //   title:'카드 신청서 상태',
        //   width:'90px',
        //   value:'cardApplication.status',
        //   filter:{
        //     name:'convertIdToText',
        //     value:'card_apply_status'
        //   },
        // },
        // {
        //   title:'카드 상태',
        //   width:'90px',
        //   value:'card.status',
        //   filter:{
        //     name:'convertIdToText',
        //     value:'card_status'
        //   },
        // },
        {
          title:'멤버십',
          width:'76px',
          value:'membershipRegistered',
          emptyValueText:'🎖',
          buttonCondition: [
            { condition:false, text:'추가', size:'small', style:'secondary_border', disabled:false, onClickEvent: (data) => this.onClickMembershipAdd(data) },
          ],
        },
        // {
        //   title:'card Application ID',
        //   width:'160px',
        //   value:'card.cardApplicationId',
        //   isAlignLeft: true,
        // },
        // {
        //   title:'카드번호',
        //   width:'160px',
        //   value:'card.cardNumber',
        //   isAlignLeft: true,
        // },
        // {
        //   title:'카드 상태',
        //   width:'80px',
        //   value:'card.status',
        //   isAlignLeft: true,
        // },
        // {
        //   title:'카드 생성 일시',
        //   width:'148px',
        //   value:'cardApplication.createdAt',
        //   filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
        //   isAlignLeft: true,
        // },
        // {
        //   title:'카드 등록 일시',
        //   width:'148px',
        //   value:'cardApplication.registeredAt',
        //   filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
        //   isAlignLeft: true,
        // },
      ],
      option:{
        isTotal: false,
        isSize: false,
        rowIdValue: 'payUserId'
      },
      markData:[
        {
          id : 'phone',
          value : ''
        }
      ]
    };
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
    this.selectedDataList = [];
    this.isCsUserByUserDataDetailPopup = false;
    this.isMembershipJoinPopup = false;
    this.detailData = {};
    this.joinModel = new joinModel();
  }
  onUpdateTab(){
    this.setTabData()
  }
  setTabData(){
    let markId = '';
    let value = this.searchData[this.tabData.value].searchDataList[0].value;
    switch(this.tabData.value){
      case 'PHONE' : { 
        this.valueText = '전화번호';
        this.apiPathData = apiPath.CS_USER_BY_PHONE;
        markId = 'phone';
        value = value.replaceAll('-','');
        break
      }
      case 'CARD_NUMBER' : { 
        this.valueText = '카드번호';
        this.apiPathData = apiPath.CS_USER_BY_CARD_NUMBER;
        markId = undefined;
        value = Number(value);
        break
      }
      case 'UID' : { 
        this.valueText = '퍼핀 UID';
        this.apiPathData = apiPath.CS_USER_BY_UID;
        markId = 'firfinUserUid';
        break
      }
      case 'ID' : { 
        this.valueText = '퍼핀 회원 ID';
        this.apiPathData = apiPath.CS_USER_BY_ID;
        markId = 'firfinUserId';
        value = Number(value);
        break
      }
      case 'PAY_ID' : { 
        this.valueText = '페이 ID';
        this.apiPathData = apiPath.CS_USER_BY_PAY_ID;
        markId = 'payUserId';
        value = Number(value);
        break
      }
      case 'VIRTUAL_ACCOUNT_NUMBER' : { 
        this.valueText = '가상계좌번호';
        this.apiPathData = apiPath.CS_USER_BY_VIRTUAL_ACCOUNT_NUMBER;
        markId = 'name';
        value = Number(value);
        break
      }
    }
    this.boardData.markData[0].id = markId;
    this.boardData.markData[0].value = value;
  }
  onClickRow(rowId,rowData){
    this.isCsUserByUserDataDetailPopup = true;
    this.detailData = rowData;
  }
  onClickCloseCsUserByUserDataDetailPopup(){
    this.isCsUserByUserDataDetailPopup = false;
    this.detailData = {};
  }
  // 멤버십 추가
  onClickMembershipAdd(data){
    this.joinModel.setUid(data.firfinUserUid);
    this.isMembershipJoinPopup = true;
  }
  onClickCloseMembershipJoinPopup(){
    this.isMembershipJoinPopup = false;
    this.joinModel = new joinModel();
  }
  onClickCompeletMembershipJoinPopup(data){
    this.joinModel.setData(data);
    this.postMembershipJoin();
  }

  onSearch(){
    this.getData();
  }
  getData(){
    let value = this.searchData[this.tabData.value].searchDataList[0].value;
    this.setTabData();
    if(this.tabData.value === 'PHONE'){
      value = value.replaceAll('-','');
      if(!isValidPhoneNumber(value)){
        store.dispatch('commonToast/fetchToastStart', `정확한 ${this.valueText}를 검색해주세요`);
        return
      }
    }
    if(this.tabData.value === 'VIRTUAL_ACCOUNT_NUMBER'){
      value = value.replaceAll('-','');
    }
    if(!value){
      store.dispatch('commonToast/fetchToastStart', `${this.valueText}를 검색해주세요`);
      return
    }
    const query = makeQueryStringByObject(this.searchData[this.tabData.value].searchParams);
    const path = `${this.apiPathData.format(value)}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      this.isCsUserByUserDataDetailPopup = false;
      const resultData = success.data;
      this.searchData[this.tabData.value].dataList = this.setData(resultData);
      this.searchData[this.tabData.value].responseDate = convertDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
      this.setSearchedItem(resultData)
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  setData(data){
    const newMapData = data.map((item) => {
      const hasMembershipData = item.memberships.length > 0;
      item.membershipRegistered = false;
      if(hasMembershipData){
        item.membershipRegistered = Boolean(item.memberships.find(item => item.status === 'REGISTERED'))
      }
      return item
    })
    return newMapData;
  }
  setSearchedItem(data){
    
    const findItem = data.find((item) => item.target);
    this.selectedDataList = [];
    if(findItem){
      this.selectedDataList.push(findItem)
    }
  }
  postMembershipJoin(){
    const path = `${apiPath.MEMBERSHIP_JOIN}`;
    const data = this.joinModel.getData();
    POST_REQUEST(path, data).then(
    (success) => {
      const resultData = success.data;
      this.isMembershipJoinPopup = false;
      store.dispatch('commonToast/fetchToastStart', '멤버십이 부여되었습니다');
      this.joinModel = new joinModel();
      this.getData();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}