export default class JoinModel {
  constructor(){
    this.firfin_user_uid = '';
    this.membership_id = '1';
    this.purchase_method_type = "FREE";
    this.amount = null;
    this.token = "";
    this.start_at = "";
  }
  setUid(uid){
    this.firfin_user_uid = uid;
  }
  setData(obj){
    const { firfin_user_uid, membership_id, purchase_method_type, amount, token,start_at } = obj;
    this.firfin_user_uid = firfin_user_uid;
    this.membership_id = membership_id;
    this.purchase_method_type = purchase_method_type;
    this.amount = amount;
    this.token = token;
    this.start_at = start_at;
  }
  getData(){
    let obj = {
      firfin_user_uid : this.firfin_user_uid,
      membership_id : Number(this.membership_id),
      purchase_method_type : this.purchase_method_type,
      amount : this.amount ? String(this.amount.replace(',','')) : 0,
      token : this.token,
      start_at : this.start_at,
    }
    return obj;
  }
}